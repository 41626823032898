import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SpinnerComponent } from '@cca/ui';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'cca-sequence-loading',
  standalone: true,
  imports: [TranslocoModule, SpinnerComponent],
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('sequence')],
})
export class SequenceLoadingComponent {}
