<ng-container *transloco="let t; read: 'sequence.confirmChangesDialog'">
  <div class="dialog-container">
    <header>
      <h2>{{ t('title') }}</h2>
      <button [mat-dialog-close]="null" class="dialog-close-button">
        <cca-icon icon="xmark"></cca-icon>
      </button>
    </header>

    <main>
      <p>
        {{ t('paragraph') }}
      </p>
    </main>

    <footer>
      <button mat-stroked-button color="warn" [mat-dialog-close]="true">
        {{ t('discard') }}
      </button>
      <button mat-flat-button color="primary" [mat-dialog-close]="false">
        {{ t('continue') }}
      </button>
    </footer>
  </div>
</ng-container>
