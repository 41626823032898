import { SequenceStepValue } from './sequence';
import { SequenceStoreStepValue } from './sequence-store-step-value';

// unwraps SequenceStepValue from JSON string value to SequenceStepValue with the normal value as was put in by the sequence
export function unwrap<
  T extends (SequenceStepValue | SequenceStoreStepValue)[] | null,
>(data: T) {
  return data
    ? data.map((x) => {
        if (typeof x.value === 'string') {
          try {
            return {
              ...x,
              // we say parse 'null' since if the value is undefined or null, it should come out as null ( undefined is a not a valid JSON type)
              value: JSON.parse((x.value as string) ?? 'null'),
            };
          } catch (e) {
            return x;
          }
        }
        return x;
      })
    : data;
}

// wraps values from sequenceStepValue to stringified JSON before sending it to backend
export function wrap<
  T extends (SequenceStepValue | SequenceStoreStepValue)[] | null,
>(data: T) {
  return data
    ? data.map((x) => {
        return {
          ...x,
          value:
            typeof x.value !== 'string' ? JSON.stringify(x.value) : x.value,
        };
      })
    : data;
}
