<ng-container *transloco="let t; read: 'sequence.component'">
  <form
    sequenceForm
    [formGroup]="form"
    id="sequence-form"
    class="h-inherit maxh custom-grid grid"
  >
    @if (completeFailedError) {
      <cca-sequence-header
        class="sequence-header"
        [title]="t('failedHeaderTitle')"
        [description]="t('failedHeaderParagraph')"
        [showContinueButton]="false"
        [showBackButton]="false"
        [continueDisabled]="true"
        repeatStepButtonText="''"
      />
    } @else {
      <cca-sequence-header
        class="sequence-header"
        [canGoBack]="!!store.previousStep()"
        [repeatableSteps]="store.repeatableSteps()"
        [isOnSummaryStep]="store.currentActiveStep()?.isSummaryStep ?? false"
        [title]="title()"
        [description]="description()"
        [hideHeaderDescription]="hideHeaderDescription"
        [continueDisabled]="completeInProgress || store.loading()"
        [showContinueButton]="showContinueButton()"
        [repeatStepButtonText]="repeatStepButtonText"
        [showDevTools]="showDevTools()"
        [finishButtonLabel]="finishButtonLabel ?? t('finishButton')"
        (backToPreviousStep)="store.navigateBackTo()"
        (continueToNextStep)="continueHandler()"
        (repeatStep)="repeatStep.emit($event)"
      />
    }
    <div
      class="h-inherit flex items-start gap-x-4 overflow-hidden pl-4"
      [ngClass]="{
        'pr-4': !showSideSummary(),
      }"
    >
      <div class="h-inherit hide-scrollbar w-full !overflow-auto !py-4">
        <div
          class="page-container {{
            store.currentActiveStep()?.stepKey
          }} w-full {{ completeFailedError ? 'h-inherit' : '' }}"
        >
          @if (completeFailedError) {
            @defer {
              <div
                class="h-inherit flex content-center items-center justify-center"
              >
                <cca-sequence-error
                  [title]="errorTitle"
                  [paragraph]="errorParagraph"
                  [showButtons]="showButtons"
                />
              </div>
            }
            <!-- <p class="text-center">{{ errorText }}</p> -->
          } @else {
            @if (completeInProgress) {
              <div class="mx-auto text-center">
                <p class="text-base">{{ completionInProgressLabel }}</p>
                <cca-spinner class="mt-2 h-12 w-12"></cca-spinner>
              </div>
            } @else {
              <ng-container *ccaSequenceHost="componentResolver"></ng-container>
            }
          }
        </div>
      </div>

      @if (showSideSummary()) {
        <div
          class="relative h-full w-72 shrink-0"
          [ngClass]="{
            'small group !w-14': summaryToggled,
          }"
        >
          <aside
            class="absolute right-0 top-4 flex h-full max-h-[calc(100vh-11rem)] w-full flex-shrink-0 flex-col justify-end overflow-hidden rounded-l-xl border border-r-0 surface-neutral-light border-neutral-default"
          >
            <ng-container
              *ccaSequenceSideSummary="sideSummaryComponent"
            ></ng-container>

            <nav
              class="flex flex-shrink-0 border-t border-solid px-2 py-1 pr-4 border-neutral-default group-[.small]:border-0"
            >
              <button
                mat-icon-button
                type="button"
                color="primary"
                class="group-[.small]:rotate-180"
                (click)="toggleSummary()"
              >
                <cca-icon icon="chevron-right"></cca-icon>
              </button>
              <button
                class="pl-2 text-base font-bold underline text-neutral-caption group-[.small]:hidden"
                type="reset"
                (click)="resetSequence()"
              >
                {{ resetSequenceLabel }}
              </button>
            </nav>
          </aside>
        </div>
      }
    </div>
  </form>
</ng-container>
