import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  inject,
  Injectable,
  InjectionToken,
  makeEnvironmentProviders,
  signal,
} from '@angular/core';
import { SequenceContextToken } from '@cca-infra/core';
import { Observable } from 'rxjs';

export const sequenceNameToken = new InjectionToken('SequenceName', {
  providedIn: 'root',
  factory: () => signal<string | null>(null),
});

@Injectable()
export class SequenceInterceptor implements HttpInterceptor {
  private sequenceName = inject(sequenceNameToken);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const sequenceName = this.sequenceName();
    if (sequenceName && req.context.get(SequenceContextToken)) {
      req = req.clone({
        params: req.params.append('sequenceName', sequenceName),
      });
    }

    return next.handle(req);
  }
}

export function provideSequenceInterceptor() {
  return makeEnvironmentProviders([
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SequenceInterceptor,
      multi: true,
    },
  ]);
}
