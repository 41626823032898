<ng-container *transloco="let t; read: 'sequence.header'">
  <header class="gap-3">
    <div class="mr-auto flex flex-col gap-1">
      <h2>{{ title }}</h2>
      @if (!hideHeaderDescription) {
        <span class="text-sm font-normal text-neutral-subtitle">
          {{ description }}
        </span>
      }
    </div>

    @if (showDevTools) {
      <cca-sequence-dev-tool class="my-auto"></cca-sequence-dev-tool>
    }

    @if (showBackButton) {
      <button
        type="button"
        mat-stroked-button
        [disabled]="!canGoBack"
        color="accent"
        (click)="backToPreviousStep.emit()"
      >
        {{ t('backButton') }}
      </button>
    }

    @if (showContinueButton) {
      <button
        type="submit"
        mat-flat-button
        color="primary"
        [disabled]="continueDisabled"
        (click)="continueOrFinish()"
      >
        {{ isOnSummaryStep ? finishButtonLabel : t('continueButton') }}
      </button>
    }
  </header>
</ng-container>
