<ng-container *transloco="let t; read: 'sequence.resetChangesDialog'">
  <div class="dialog-container">
    <header>
      <h2>{{ t('title') }}</h2>
    </header>

    <main>
      <p>
        {{ t('paragraph') }}
      </p>
    </main>

    <footer>
      <button mat-stroked-button color="accent" [mat-dialog-close]="false">
        {{ t('cancel') }}
      </button>
      <button mat-flat-button color="warn" [mat-dialog-close]="true">
        {{ t('reset') }}
      </button>
    </footer>
  </div>
</ng-container>
