import { assertInInjectionContext, inject, isDevMode } from '@angular/core';
import { SequenceStore } from '../sequence-store';

export type sequenceMetaDataOptions<T> = {
  defaultValue?: T;
  parse?: boolean;
};

// Function Overloads

// When not parsing but providing a defaultValue we expect T to be a string
export function sequenceMetaData<T extends string>(
  property: string,
  options: Omit<sequenceMetaDataOptions<T>, 'parse' | 'defaultValue'> & {
    defaultValue: string;
    parse: false;
  },
): T;

// When not parsing we expect T to be a string | null
export function sequenceMetaData<T extends string>(
  property: string,
  options: Omit<sequenceMetaDataOptions<T>, 'parse'> & { parse: false },
): T | null;

// When providing a defaultValue the return type should be a T
export function sequenceMetaData<T>(
  property: string,
  options: Omit<sequenceMetaDataOptions<T>, 'defaultValue'> & {
    defaultValue: T;
  },
): T;

// When not providing a defaultValue the return should T | null
export function sequenceMetaData<T>(
  property: string,
  options?: sequenceMetaDataOptions<T>,
): T | null;

// Function Implementation
export function sequenceMetaData<T>(
  property: string,
  options?: sequenceMetaDataOptions<T>,
): T | null {
  isDevMode() && assertInInjectionContext(sequenceMetaData);
  const store = inject(SequenceStore);
  const step = store.currentActiveStep();

  const activeStepMetaData = step?.metaData ?? [];
  if (!activeStepMetaData.length) {
    return options?.defaultValue ?? null;
  }

  const rawMetaData = activeStepMetaData.find(
    (value) => value.identifier === property,
  )?.value;
  if (!rawMetaData) {
    if (isDevMode()) {
      console.warn(
        `Metadata for property "${property}" was not found or is null/undefined.`,
      );
    }

    return options?.defaultValue ?? null;
  }

  // if parse is a boolean and false, aka you did explicity say to not parse return the rawMetaData without parsing
  if (typeof options?.parse === 'boolean' && !options.parse) {
    // no need to check for null above case already checks if rawMetaData is not null
    return rawMetaData as T;
  }

  try {
    // Backend sometimes returns these as 'False', 'True' or 'Null', when parsing these we get a JSON error
    // by doing a toLowerCase we get the values we need correctly without doing type casting
    if (['False', 'True', 'Null'].includes(rawMetaData)) {
      return JSON.parse(rawMetaData.toLowerCase());
    }
    return JSON.parse(rawMetaData);
  } catch (error) {
    console.error(`could not read meta data from ${step?.stepKey}`, error);
  }

  return options?.defaultValue ?? null;
}
